export interface PromisedData {
  data: object,
  loading: boolean,
}

export enum InsuranceType {
  auto = 'auto',
  home = 'home',
  business = 'business',
  health = 'health',
  life = 'life',
  retirement = 'retirement',
  disability = 'disability',
  medicare = 'medicare',
  other = 'other',
}
